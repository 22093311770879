<template>
    <div class="col-sm-12 nopads">
        <b-form class="col-sm-12 editor-form">
            <div class="col-sm-12 form-title editor-title">
                <h2
                    v-if="userUpdate.id > 0"
                    class="form-title editor-title"
                >
                    {{ $t('users.edit_user') }}
                </h2>
                <h2
                    v-else
                    class="form-title editor-title"
                >
                    {{ $t('users.add_user') }}
                </h2>
            </div>
            <div class="col-sm-12 form-data">
                <span class="span-title required">
                    {{ $t('users.user_name') }}
                </span>
                <b-form-group
                    class="title"
                    label-for="username"
                >
                    <b-form-input
                        id="username"
                        v-model="userUpdate.username"
                        type="text"
                        :state="usernameState"
                        size="sm"
                        :disabled="userUpdate.id > 0"
                    />
                </b-form-group>
                <span class="span-title required">
                    {{ $t('users.first_name') }}
                </span>
                <b-form-group
                    class="title"
                    label-for="first_name"
                >
                    <b-form-input
                        id="first_name"
                        v-model="userUpdate.first_name"
                        type="text"
                        :state="firstnameState"
                        size="sm"
                    />
                </b-form-group>

                <span class="span-title required">
                    {{ $t('users.last_name') }}
                </span>
                <b-form-group
                    class="title"
                    label-for="last_name"
                >
                    <b-form-input
                        id="last_name"
                        v-model="userUpdate.last_name"
                        type="text"
                        :state="lastnameState"
                        size="sm"
                    />
                </b-form-group>

                <span class="span-title" :class="{ required: phoneMandatory }">
                    {{ $t('users.phone') }}
                </span>
                <b-form-group
                    class="title"
                    label-for="phone"
                >
                    <b-form-input
                        id="phone"
                        v-model="userUpdate.phone"
                        type="text"
                        :state="phoneState"
                        size="sm"
                    />
                </b-form-group>

                <div class="nopads" v-if="isAdmin">
                    <span class="span-title">
                      {{ $t('users.company') }}
                    </span>
                    <b-form-group
                        class="title"
                        label-for="last_name"
                    >
                        <b-form-select
                            id="customer"
                            v-model="company"
                            size="sm"
                            class="mb-3"
                        >
                            <template slot="first">
                                <option
                                    :value="null"
                                    disabled
                                >
                                    {{ $t('users.select_company') }}
                                </option>
                            </template>
                            <option
                                v-for="c in companies"
                                :key="c.id"
                                :value="c.id"
                            >
                                {{ c.name }}
                            </option>
                        </b-form-select>
                    </b-form-group>

                </div>
                <div class="nopads" v-if="isAdmin">
                    <span class="span-title required">
                        {{ $t('users.license_holder') }}
                    </span>
                    <b-form-group
                        class="title"
                        label-for="license_holder"
                    >
                        <b-form-select
                            id="license"
                            v-model="licenseHolder"
                            size="sm"
                            :state="licenseHolder !== null"
                            class="mb-3"
                            :disabled="!companies || companies.length < 1 "
                        >
                            <template slot="first">
                                <option
                                    :value="null"
                                    disabled
                                >
                                    {{ $t('users.select_company') }}
                                </option>
                            </template>
                            <option
                                v-for="c in companies"
                                :key="c.id"
                                :value="c.id"
                            >
                                {{ c.name }}
                            </option>
                        </b-form-select>
                    </b-form-group>
                </div>

                <span class="span-title">
                    {{ $t('users.tax_number') }}
                </span>
                <b-form-group
                    class="title"
                    label-for="tax_number"
                >
                    <b-form-input
                        id="tax_number"
                        v-model="userUpdate.tax_number"
                        type="text"
                        size="sm"
                    />
                </b-form-group>

                <span class="span-title required">
                    {{ $t('users.email') }}
                </span>
                <b-form-group
                    class="title"
                    label-for="email"
                >
                    <b-form-input
                        id="email"
                        v-model="userUpdate.email"
                        :state="emailState"
                        type="text"
                        size="sm"
                    />
                </b-form-group>

                <span class="span-title">
                    {{ $t('common.info') }}
                </span>
                <b-form-group
                    class="title"
                    label-for="info"
                >
                    <b-form-textarea
                        id="info"
                        v-model="userUpdate.info"
                        :placeholder="$t('vehicle_editor.additional_info')"
                        :rows="2"
                        :max-rows="6"
                    />
                </b-form-group>

                <!-- New user rights -->
                <div
                    class="col-sm-12 nopads"
                >
                    <span class="span-title">
                        {{ $t('users.rights') }}
                    </span>
                    <user-rights-editor
                        ref="userRights"
                        :is-admin="isAdmin"
                        :rights="userRights"
                        :companies="companies"
                        :roles="roles"
                        @addUserRight="addUserRight"
                        @removeUserRight="removeUserRight"
                    />
                </div>
            </div>
            <div class="col-sm-12 button-container pb-3">
                <b-button
                    variant="primary"
                    class="form-button"
                    :disabled="!!loading || userToEdit.id < 1"
                    @click.stop="getResetLink"
                >
                    {{ $t('users.reset_password') }}
                </b-button>
                <b-button
                    variant="danger"
                    class="form-button"
                    @click.stop="$emit('close')"
                >
                    {{ $t('common.cancel') }}
                </b-button>
                <b-button
                    variant="success"
                    class="form-button"
                    :disabled="formState"
                    @click.stop="submit"
                >
                    {{ $t('common.save') }}
                </b-button>
            </div>
        </b-form>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {EventBus} from '@/event-bus'
import {restApi} from '../mixins/RestApiMixin'
import {userHelper} from '../mixins/UserMixin'
import UserRightsEditor from "./UserRightsEditor";

export default {
    name: 'UserEditor',
    components: {UserRightsEditor},
    mixins: [userHelper, restApi],
    props: {
        user: {
            type: Object,
        },
        userToEdit: {
            type: Object,
            default: null
        },
        isAdmin: {
            type: Boolean,
            default: false
        },
        phoneMandatory: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            loading: 0,
            company: null,
            licenseHolder: null,
            companies: [],
            roles: [],
            userRights: [],
            resetLink: null,
            userUpdate: this.jsonToUser(this.userToEdit)
        }
    },
    computed: {
       phoneState() {
           if (this.phoneMandatory) {
               return !!(this.userUpdate.phone && this.userUpdate.phone.length > 4)
           }
           return null
       },
       emailState() {
           return this.validEmail(this.userUpdate.email)
       },
       usernameState() {
           return !!(this.userUpdate.username && this.userUpdate.username.length > 2)
       },
       firstnameState() {
          return !!(this.userUpdate.first_name && this.userUpdate.first_name.length > 0)
       },
       lastnameState() {
          return !!(this.userUpdate.last_name && this.userUpdate.last_name.length > 1)
       },
       formState () {
           return !this.emailState || !this.usernameState || !this.firstnameState || !this.lastnameState || !!this.loading
       }
    },

    mounted() {
        this.fetchRoles()
        this.fetchCompanies()
        if (this.userUpdate.company) {
            this.company = this.userUpdate.company.id
            this.companies.push(this.userUpdate.company)
        }
        if (this.userToEdit.rights) {
            this.userRights = JSON.parse(JSON.stringify(this.userToEdit.rights))
        }
    },

    methods: {
        async fetchRoles() {
            this.loading++;
            const { err, ...response } = await this.axios.get(this.userRoleUrl).catch((err) => ({err}));
            this.loading--;
            if (err) {
                // Do something
                return;
            }
            this.roles = response.data;
        },

        async fetchCompanies() {
            this.loading++;
            const { err, ...response } = await this.axios.get(this.companyUrl).catch((err) => ({err}));
            this.loading--;
            if (err) {
                // Do something
                return;
            }
            this.companies = response.data;
            if (this.userUpdate.license_holder) {
                this.licenseHolder = this.userUpdate.license_holder.id
            }
        },

        setDefaultValues(user) {
            if (!user.username) {
                EventBus.$emit('show-alert', this.$t('users.err_username'))
                return
            }
            if (!user.email) {
                EventBus.$emit('show-alert', this.$t('users.err_email'))
                return
            }
            if (this.isAdmin) {
                if (this.company) {
                    user.company = this.companies.find(company => company.id === this.company)
                } else {
                    EventBus.$emit('show-alert', this.$t('users.err_select_company'))
                    return
                }
                if (this.licenseHolder) {
                    user.license_holder = this.companies.find(company => company.id === this.licenseHolder)
                    user.company = this.companies.find(company => company.id === this.company)
                } else {
                    EventBus.$emit('show-alert', this.$t('users.err_select_license_holder'))
                    return
                }
            }
            else {
                user.company = this.userUpdate.company;
                user.license_holder = this.userUpdate.license_holder || this.userToEdit.company
            }
            user.roles = []
            // User rights
            user.rights = this.userRights
            return user
        },

        submit() {
            // set user right that was not added by plus button
            let userRight = this.$refs.userRights.$refs.selector.addUnAddedUserRight()
            if(userRight) {
                this.addUserRight(userRight)
            }
            this.submitUser()
        },

        submitUser() {
            var jsonUser = this.setDefaultValues(this.userUpdate)
            if (jsonUser === undefined) {
                return;
            }
            this.loading++;

            if (jsonUser.id < 1) {
                this.restAdd(this.userUrl, jsonUser, this.success, this.fail)
            } else {
                this.restUpdate(this.userUrl, jsonUser, this.success, this.fail)
            }
        },

        success () {
            this.loading--
            this.$emit('closeAndUpdate')
        },

        fail (response) {
            this.loading--
            if (response.status === 409) {
                EventBus.$emit('show-alert', this.$t('common.alert_username_exists'));
            } else {
                EventBus.$emit('show-alert', this.$t('common.alert_update_failed'));
            }
        },

        async addUserRight(userRight) {
            if (!this.isAdmin) {
                userRight.company = this.user.company;
            }
            if (!userRight.company || !userRight.role) {
                // Do something
                return;
            }
            // Check if role - company pair already exists and if not, add to user rights array
            if (this.userRights.find(
                (item) => item.company.id === userRight.company.id
                    && item.role.id === userRight.role.id
                    && !item.removed_at
            )) {
                // Do something
                return;
            }
            this.userRights.push(userRight);
            await this.$nextTick();
            this.$refs.userRights.reset();
        },

        removeUserRight(userRight) {
            if (userRight.id > 0) {
                // Exists in database - remove right properly
                const rightIndex = this.userRights.findIndex((right) => right.id === userRight.id);
                const right = this.userRights.splice(rightIndex, 1)[0];
                right.removed_at = new Date().toISOString();
                this.userRights.push(right);
            } else {
                // Not yet in database, just remove from list
                const index = this.userRights.findIndex(
                    (value) =>
                        value.role.id === userRight.role.id
                        && value.company.id === userRight.company.id
                        && !value.removed_at
                )
                this.userRights.splice(index, 1);
            }
        },

        async getResetLink() {
            this.resetLink = undefined
            this.loading++;
            const response = await this.axios.get(this.resetPasswordAdminUrl, { params: { user: this.userToEdit.id } });
            this.loading--;
            if (response && response.data) {
                this.resetLink = response.data.link
                if (this.resetLink) {
                    window.location.href = "mailto:" + this.userToEdit.email + "?subject=" + this.$t('users.reset_password') + "&body=" + this.resetLink;
                }
            }
        },

    }
}
</script>
